import React, { useEffect, useState } from 'react'
import { Avatar } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { IconButton } from '@mui/material';
import "../css/chat.css"
import { useParams } from 'react-router-dom';
import { db } from './Data';
import { useStateValue } from './StateProvider';
import firebase from 'firebase/compat/app';
function Chat() {


    const [seed, setSeed] = useState();
    const [permission, setPermission] = useState(false)
    const [mypass, setmyPass] = useState("")
    const [correctPass, setCorrectPass] =useState("")
    useEffect (()=>{
        setSeed(Math.floor(Math.random() * 5000))
    },[])

    const [{user}, dispatch] = useStateValue();

    const {roomId} = useParams();
    console.log(roomId);

    const [input, setInput] = useState("");
    const [messages, setMessges] = useState([""]);
    const [roomName, setRoomName] = useState("");
    useEffect(()=>{
        setPermission(false)
        if(roomId){
            db.collection("rooms").doc(roomId).onSnapshot(snapshot=>{
                setRoomName(snapshot.data().name);
            });
            
            db.collection("rooms").doc(roomId).onSnapshot(snapshot=>{
                setCorrectPass(snapshot.data().password);
            })

            db.collection("rooms").doc(roomId).collection("message").orderBy("timeStamp", "asc").onSnapshot(snapshot=>{
                setMessges(snapshot.docs.map(doc=>doc.data()))
            })

        }
    }, [roomId])

    const checkPassword = ()=>{
        console.log(mypass)
        console.log(correctPass)
        if (mypass===correctPass) {
            setPermission(true)
        }
        else{
            alert("Enter correct Password")
        }
        
    }

    const sendMessage=(e)=>{
        e.preventDefault();

        if (input==="") {
            alert("Please Enter Messege");
        }

        db.collection("rooms").doc(roomId).collection("message").add({
            name:user.displayName,
            message:input,
            timeStamp:firebase.firestore.FieldValue.serverTimestamp()
        }); 
        setInput("");
    }

  return (
<>
    

    <div className='flex sm:ml-[25vw] ml-[1vw] h-screen flex-col'>

        <div className="chat-header flex bg-gray-200">
            <Avatar src={`https://api.dicebear.com/7.x/adventurer/svg?seed=${seed}`}/>
            <div className="chat-headerInfo">
            <h3>{roomName}</h3>
            <p>
                {
                    new Date(messages[messages.lenght-1]?.timeStamp?.seconds*1000).toLocaleTimeString()
                }
            </p>
            </div>

            <div className="chat-header-right">
            <IconButton>
            <SearchIcon/>
            </IconButton>
        

            <IconButton>
            <AttachFileIcon/>
            </IconButton>


            <IconButton>
            <MoreVertIcon/>
            </IconButton>
            </div>
        </div>
      
    <div className="chat-body flex-grow overflow-y-scroll">


        {
            permission?<>
            {messages.map(message=>(
                <p className={`max-w-[90vw]  chat-messege ${user.displayName===message.name && "chat-received"}`}>
                    <span className="chat-name">{message.name}</span>
                    {message.message}
                    <span className="chat-time">{
                        new Date(message.timeStamp?.seconds*1000).toLocaleTimeString()
                    }</span>
                </p>
               ))} </>
               :
               <div className='Password-box'>
        <label> Enter Room's Password</label>
        <input className='input-text border' onChange={e=>setmyPass(e.target.value)} type="password" />
        <button onClick={checkPassword} class="button-3" >Submit</button>
    </div>
        }
        

    </div>


{permission?
<>
    <div className="chat-footer flex bg-gray-200 ">
               <form onSubmit={sendMessage}>
                    <input type="text" value={input} placeholder='Type your messege' onChange={e=>setInput(e.target.value)}/> 
                    <input type="submit" />
               </form>
               
               
               </div>

   </>:<div></div>
}
</div> 
    </>
  )
}

export default Chat
