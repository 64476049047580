import React from 'react'
import "../css/Login.css"
import { useStateValue } from './StateProvider'
import { auth, provider } from './Data';

function Login() {
  const [{user}, dispatch] = useStateValue()

  const signIn = ()=>{
    auth.signInWithPopup(provider).then(result=>{
      dispatch({
        type:"set-user",
        user: result.user
      })
    }).catch(error=>alert(error))
  }


  

  return (
    <div className='login-wrapper'>
        <div className="login flex flex-col align-item-center justify-center">
      <img className='align-center item-center justify-self-center self-center' src="https://i.pinimg.com/originals/54/99/5c/54995c1227dade2c55d783d5069976c9.png" alt="logo" />
      <h2>Sign in to Whatsapp</h2>
      <button onClick={signIn}>Login with Gmail</button>
      </div>
    </div>
  )
}

export default Login
