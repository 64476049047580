import React, { useEffect, useState } from 'react'
import { Avatar } from '@mui/material'
import '../css/Sidebar.css'
import { db } from './Data'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { Link } from 'react-router-dom'
function Sidebarchat({ id, name, addnewchat }) {
  const [seed, setSeed] = useState('')
  const [lastMsg, setLastMsg] = useState('')
  const [correctPass, setCorrectPass] = useState('')
  // eslint-disable-next-line

  useEffect(() => {
    setSeed(Math.floor(Math.random() * 5000))

    db.collection('rooms')
      .doc(id)
      .collection('message')
      .orderBy('timeStamp', 'desc')
      .onSnapshot((snapshot) => {
        setLastMsg(snapshot.docs.map((doc) => doc.data()))
      })
  }, [id])

  const createChat = () => {
    const room = prompt('Enter Room Name')
    const Pass = prompt("Enter Room's Password")
    if (room) {
      db.collection('rooms').add({
        name: room,
        password: Pass,
      })
    }
  }

  const removeChat = (id) => {
    const myPass = prompt('Please Enter Password to delete the chatbox')
    db.collection('rooms')
      .doc(id)
      .onSnapshot((snapshot) => {
        setCorrectPass(snapshot.data().password)
      })
    console.log(myPass, correctPass)
    if (myPass === correctPass) {
      db.collection('rooms').doc(id).delete()
    } else {
      alert('Enter correct password')
    }
  }

  return !addnewchat ? (
    <Link to={`/room/${id}`}>
      <div className="sidebar-chat flex">
      
        <div>
        <Avatar
          src={`https://api.dicebear.com/7.x/adventurer/svg?seed=${seed}`}
        />
        </div>
      
        <div
          style={{ textDecoration: 'none !important' }}
          className="sidebar-chatinfo"
        >
          <h2>{name}</h2>
          <p>{lastMsg[0]?.message}</p>
        </div>
        
        <div className="" onClick={() => removeChat(id)}>
          <DeleteOutlineIcon/>
        </div>
      
      </div>
    </Link>
  ) : (
    <div className="sidebar-chat" onClick={createChat}>
      <h2>Add New Chat</h2>
    </div>
  )
}

export default Sidebarchat
