import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyBunOcqK_tYq6CYXJBCjumtHBwasNGfOMA",
  authDomain: "whatsaap-clone-hs.firebaseapp.com",
  projectId: "whatsaap-clone-hs",
  storageBucket: "whatsaap-clone-hs.appspot.com",
  messagingSenderId: "114426830887",
  appId: "1:114426830887:web:a68320c331fa1d90e26e02"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

const db = firebaseApp.firestore();
const auth = firebase.auth();
const provider = new firebase.auth.GoogleAuthProvider();

export { auth, db, provider  };