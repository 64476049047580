import Avatar from '@mui/material/Avatar'
import { IconButton } from '@mui/material'
import React, { useState } from 'react'
import DonutLargeIcon from '@mui/icons-material/DonutLarge'
import ChatIcon from '@mui/icons-material/Chat'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import SearchIcon from '@mui/icons-material/Search'
import '../css/Sidebar.css'
import Sidebarchat from './Sidebarchat'
import { useEffect } from 'react'
import { db } from './Data'
import { useStateValue } from './StateProvider'
import firebase from 'firebase/compat/app'

export default function Sidebar() {
  const [rooms, setRooms] = useState([])
  const [{ user }, dispatch] = useStateValue()
  const [isOpen, setIsOpen] = useState(false);
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
};

const closeSidebar = () => {
    setIsOpen(false);
};

  useEffect(() => {
    db.collection('rooms').onSnapshot((snapshot) => {
      setRooms(
        snapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        })),
      )
    })
  }, [])

  return (

<>
<button
                onClick={toggleSidebar}
                type="button"
                className="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            >
                <span className="sr-only">{isOpen ? 'Close sidebar' : 'Open sidebar'}</span>
                <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    {isOpen ? (
                        <path
                            clipRule="evenodd"
                            fillRule="evenodd"
                            d="M4.75 2A.75.75 0 014 2.75v14.5a.75.75 0 011.5 0V2.75A.75.75 0 014.75 2zm10.5 0a.75.75 0 00-.75.75v14.5a.75.75 0 001.5 0V2.75a.75.75 0 00-.75-.75zM10 2a.75.75 0 00-.75.75v14.5a.75.75 0 001.5 0V2.75A.75.75 0 0010 2z"
                        ></path>
                    ) : (
                        <path
                            clipRule="evenodd"
                            fillRule="evenodd"
                            d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5h-14.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                        ></path>
                    )}
                </svg>
            </button>

            <aside
                id="sidebar-multi-level-sidebar"
                className={`fixed top-0 left-0 z-40 w-[full] h-screen transition-transform ${
                    isOpen ? '' : '-translate-x-full sm:translate-x-0'
                }`}
                aria-label="Sidebar"
            >
                <div className="h-full px-3 py-4 w-[80vw] sm:w-[25vw] overflow-y-auto bg-gray-50 dark:bg-gray-800">
                    <button
                        onClick={closeSidebar}
                        type="button"
                        className="absolute top-0 right-0 m-4 text-gray-500 sm:hidden"
                    >
                        <span className="sr-only">Close sidebar</span>
                        <svg
                            className="w-6 h-6"
                            aria-hidden="true"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M13.178 5.86a.75.75 0 01.885 1.21l-7.071 8a.75.75 0 11-1.167-.943l7.071-8a.75.75 0 01.282-.267z"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M6.107 5.86a.75.75 0 10-1.167.943l7.071 8a.75.75 0 001.167-.943l-7.071-8a.75.75 0 00-.282-.267z"
                            />
                        </svg>
                    </button>


    <div className="sidebar">
      <div className="sidebar-header">
        <Avatar
          src={user.photoURL}
          onClick={(e) => firebase.auth().signOut()}
        />
        <div className="sidebar-header-right">
          <IconButton>
            <DonutLargeIcon />
          </IconButton>

          <IconButton>
            <ChatIcon />
          </IconButton>

          <IconButton>
            <MoreVertIcon />
          </IconButton>
        </div>
      </div>
      <div className="sidebar-search">
        <div className="sidebar-search-container">
          <SearchIcon />
          <input type="text" placeholder="search or start a new chat" />
        </div>
      </div>

      <div className="sidebar-chats">
        <Sidebarchat addnewchat />
        {rooms.map((room) => {
          return (
            <Sidebarchat key={room.id} id={room.id} name={room.data.name} />
          )
        })}
      </div>
    </div>
    </div>
    </aside>

</>

    
  )
}
