import './App.css';
import Sidebar from './components/Sidebar';
import Chat from './components/Chat';
import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";
import Login from './components/Login';
import { useStateValue } from './components/StateProvider';
import { auth } from './components/Data';
import { Grid } from '@mui/material';

function App() {

  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const [{user}, dispatch] = useStateValue();

  useEffect(()=>{
    auth.onAuthStateChanged(user=>{
      dispatch({
        type:"set-user",
        user:user
      })
    })
  }, [])

  return (

    <Router>

{!user ? (<Login/>):(
  
<div className='m-5'>
  

  
    <Sidebar className="h-[90vh]"/>
  

  
  <Routes>
     <Route style={{textDecoration:"none"}} path='/room/:roomId' element={<Chat/>}></Route>
     <Route exact path='/' element={<Chat/>}></Route>
   </Routes>
  
  </div>

//       <div className="App">
    
//   <div className="app-body">



  
   

//   </div>
//  </div>









 
)}

    
    </Router>
    
  );
}

export default App;
